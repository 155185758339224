import { createStore, combineReducers } from 'redux';
import {
    IsKelulusan
} from './Reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'

const MainReducer = combineReducers({
    loadingBar: loadingBarReducer,
    isKelulusan:IsKelulusan
});

export const store = createStore(MainReducer);