import React, {lazy} from 'react';
import {
        BrowserRouter as Router,
        Route,
        Switch,
        } from 'react-router-dom'

const Home = lazy(() => import('./../component/Home'));

const Navigation=({test})=>{
  return(
      <Switch>
          {/* dashboard */}
          <Route exact path="/:code?" component={Home}/>
      </Switch>
  );
}

export default Navigation;