import React, { Suspense, Component } from 'react';
import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import { store } from './redux_handler/Store';
import { Provider } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import Navigation from './navigation/Index';

class App extends Component{
  render(){
    return (
      <Provider store={store} className="App">
        <Router>
          <Suspense fallback={<div>Memuat Ulang...</div>}>
            <LoadingBar style={{zIndex:999, position:'fixed'}}/>
            <Navigation />
          </Suspense>
        </Router>
      </Provider>
    );
  }
}
export default App;
