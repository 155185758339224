const kelulusan = {
    nisn: '',
    req_api_init:{},
    req_api_real:{}
}

export const IsKelulusan = (state = kelulusan, action) => {
    switch (action.type) {
        case 'SET_NISN':
            return {
                ...state,
                nisn: true
            }
            break;
        case 'GET_RESPONSE_INIT':
            return {
                ...state,
                req_api_init: action.data
            }
            break;
        case 'GET_RESPONSE_REAL':
            return {
                ...state,
                req_api_real: action.data
            }
            break;
        default:
            break;
    }
    return state;
}

const loading = {
    loading: false
}

export const IsLoading = (state = loading, action) => {
    switch (action.type) {
        case 'LOADING_TRUE':
            return {
                ...state,
                loading: true
            }
            break;
        case 'LOADING_FALSE':
            return {
                ...state,
                loading: false
            }
            break;
        default:
            break;
    }
    return state;
}